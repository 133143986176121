jQuery(document).ready(function ($) {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});


	$('.content').on('click','#kviz-form input',function () {
		kerdesbetolt($('#kviz-form'), $(this));
	});

	function kerdesbetolt($this, $button) {
		$.ajax({
			url: $this.attr('action'),
			method: $this.data('method') || 'POST',
			data: $this.serialize(),
			dataType: 'json',
			success: function (data) {
				if (! data.helyes) {
					$button.parent().css('background','red');
				}else{
					$button.parent().css('background','green');
				}
				$button.parent().parent().siblings('.radio').css('opacity','.5');

				setTimeout(function() {
					$(".content").html(data.view);
				},1500);

			},
			error: function (xhr, textStatus, e) {
				alert(xhr.responseText+", "+xhr.statusText+", "+xhr.status+", "+xhr.readyState+", "+textStatus+", "+e);
			}
		});
	}
});


$(document).on('submit','#reg-form',function (e) {
	e.preventDefault();
	var $this = $(this);

	$.ajax({
		url: $this.attr('action'),
		method: $this.data('method') || 'POST',
		data: $this.serialize(),
		dataType: 'html',
		success: function (data) {
			$(".form-contet").html(data);
		},
		error: function (xhr, textStatus, e) {
			alert('Valami hiba történt! Lehet, hogy ezzel az email címmel már játszottak ebben a körben');
		}
	});
});

